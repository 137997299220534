import {
  ref, getCurrentInstance,
} from '@vue/composition-api';

export default function useReportCashFlow() {
  const blankItem = {
    id: 0,
    apartment: null,
    floor: null,
    name: '',
    price: null,
    deposit: null,
    size: null,
    active: true,
  };

  const item = ref(JSON.parse(JSON.stringify(blankItem)));
  const resetItem = () => {
    item.value = JSON.parse(JSON.stringify(blankItem));
  };

  const vm = getCurrentInstance().proxy;

  // filter
  const apartment = ref(null);
  const year = ref(null);

  return {
    item,
    apartment,
    year,

    resetItem,

  };
}
